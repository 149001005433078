<template>
    <v-card flat class="gst-ticket-offer-small-card px-0" :title="offer.name" @click="$emit('click')">
        <div align-center class="d-flex pb-2">
            <v-flex xs10 class="pr-2">
                <h5>
                    <TicketTypeIconVariantOne :type="ticket.selection" :offer-name="offer.name" />
                </h5>
                <VClamp
                    autoresize
                    :max-lines="1"
                    class="hyphens clamp gst-ticket-offer-small-card__seat">
                    {{ ticket | seatLocation }}
                </VClamp>
                <!-- eslint-disable vue/no-v-html -->
                <VClamp
                    autoresize
                    :max-lines="2"
                    class="hyphens clamp gst-ticket-offer-small-card__price"
                    v-html="offerPrice" />
                <!-- eslint-enable vue/no-v-html -->
            </v-flex>
            <v-flex shrink>
                <PriceIcon
                    :price="price"
                    :points="offerPoints"
                    :discounts="offer.discounts"
                    :offer="offer"
                    :currency="currency"
                    :loyalty-currency="loyaltyCurrency"
                    :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney"
                    :is-starting-price="offers.length > 1" />
            </v-flex>
        </div>
    </v-card>
</template>
<script>
    import VClamp from 'vue-clamp';
    import TicketTypeIconVariantOne from '@core/shared/components/ticket/TicketTypeIconVariantOne.vue';
    import PriceIcon from '@core/shared/components/misc/PriceIcon.vue';
    import { getViewedPriceOfferForEvent as ticketOfferUtilsGetViewedPriceOfferForEvent } from '@core/utils/ticketOfferUtils';

    export default {
        name: 'TicketCardVariantOne',
        components: {
            TicketTypeIconVariantOne,
            VClamp,
            PriceIcon
        },
        i18nOptions: {
            keyPrefix: 'components.ticket.ticketOfferCardSmall',
            namespaces: 'shared'
        },
        props: {
            ticket: {
                type: Object,
                required: true
            },
            offers: {
                type: Array,
                required: true
            },
            isLoyaltyCurrencyTypeMoney: {
                type: Boolean,
                required: true
            },
            event: {
                type: Object,
                required: true
            },
        },
        computed: {
            offer( ) {
                return this.offers[ 0 ];
            },
            currency( ) {
                const { offer } = this;
                return offer.currency;
            },
            loyaltyCurrency( ) {
                const { offer } = this;
                return offer.loyaltyCurrency;
            },
            offerPrice( ) {
                const { event, offer } = this;
                return ticketOfferUtilsGetViewedPriceOfferForEvent( event, offer, { variant: 1 } );
            },
            offerPoints( ) {
                return this.offer.bonusPoints;
            },
            price( ) {
                const { event, offer } = this;
                return ticketOfferUtilsGetViewedPriceOfferForEvent( event, offer, { variant: 3 } );
            }
        }
    };
</script>
<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-ticket-offer-small-card {
    position: relative;
    background-color: transparent;
    max-width: 100%;

    .gst-ticket-offer-small-card__seat {
        line-height: 19px;
        margin-bottom: theme-spacing( 1 );
        font-size: font-size( 'l' );
        font-weight: font-weight( 'medium' );
    }

    .gst-ticket-offer-small-card__price {
        line-height: 14px;
        color: theme-color( 'tertiary' );
        font-size: font-size( 'xxs' );
        font-weight: font-weight( 'regular' );
    }
}
</style>

import { render, staticRenderFns } from "./EventTicketDetailSubtotal.vue?vue&type=template&id=2388771f&scoped=true&CORE"
import script from "./EventTicketDetailSubtotal.vue?vue&type=script&lang=js&CORE"
export * from "./EventTicketDetailSubtotal.vue?vue&type=script&lang=js&CORE"
import style0 from "./EventTicketDetailSubtotal.vue?vue&type=style&index=0&id=2388771f&lang=scss&scoped=true&CORE"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2388771f",
  null
  
)

export default component.exports
<template>
    <div class="gst-event-provider-purchase-notification d-flex">
        <BaseIcon symbol-id="icons--alert--warning" class="gst-event-provider-purchase-notification__icon" />
        <div class="gst-event-provider-purchase-notification__content">
            <p class="message">
                {{ $t('message') }}
            </p>
            <BaseButton classes="link d-flex" @click="$emit('click')">
                <span class="link__text">
                    {{ $t('linkText') }}
                </span>
                <BaseIcon symbol-id="icons--dropdown_arrow" class="icon" />
            </BaseButton>
        </div>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'EventProviderPurchaseNotification',
        components: {
            BaseButton,
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.event.theEvent._components.eventProviderPurchaseNotification',
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-event-provider-purchase-notification {
        position: relative;
        padding: theme-spacing( 4, 3 );
        margin-top: theme-spacing( 2 );
        background-color: theme-color( 'white' );
        box-shadow: 0 1px 4px 1px rgba( theme-color-hex( 'tertiary' ), 0.35596 );
        border-radius: border-radius( 's' );
        margin-left: theme-spacing( 2 );

        .gst-event-provider-purchase-notification__icon {
            margin: theme-spacing( 0, 3 );
            min-width: 24px;

            .gst-svg-icon {
                fill: theme-color( 'primary' );
            }
        }

        .gst-event-provider-purchase-notification__content {
            line-height: line-height( 'xxxl' );
            color: theme-color( 'quaternary' );
            font-size: font-size( 'l' );
            font-weight: font-weight( 'medium' );
            letter-spacing: -0.470588px;

            .message {
                margin-bottom: theme-spacing( 4 );
            }

            .link {
                width: fit-content;
                padding: theme-spacing( 0 ) !important;
                border: none !important;
                background-color: transparent !important;

                .link__text {
                    line-height: line-height( 'xxxxl' );
                    margin-right: theme-spacing( 2 );
                    color: theme-color( 'primary' );
                }

                .icon {
                    width: 24px;
                    transform: rotate( -90deg );

                    .gst-svg-icon {
                        fill: theme-color( 'primary' );
                    }
                }
            }
        }
    }

    .gst-event-provider-purchase-notification::before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: theme-spacing( 1 );
        background-color: theme-color( 'primary' );
        content: '';
        border-top-left-radius: border-radius( 's' );
        border-bottom-left-radius: border-radius( 's' );
    }
</style>

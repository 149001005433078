<template>
    <component :is="componentWarning" :event="event" />
</template>

<script>
    import eventConstants from '@core/utils/constants/event';
    import {
        isPast as eventUtilsIsPast,
        isSaleDatesInFuture as eventUtilsIsSaleDatesInFuture
    } from '@core/utils/eventUtils';
    import EventStateNoDateWarningCard from './notifications/EventStateNoDateWarningCard';
    import EventStateOnSaleDateFutureWarningCard from './notifications/EventStateOnSaleDateFutureWarningCard';
    import EventStatePastWarningCard from './notifications/EventStatePastWarningCard';
    import EventStateNonTransactableWarningCard from './notifications/EventStateNonTransactableWarningCard';
    import EventStatusCancelledWarningCard from './notifications/EventStatusCancelledWarningCard';
    import EventTicketStatusNotAvailableWarningCard from './notifications/EventTicketStatusNotAvailableWarningCard';
    import EventStateInactiveWarningCard from './notifications/EventStateInactiveWarningCard';
    import EventStateNotAvailableWarningCard from './notifications/EventStateNotAvailableWarningCard';

    export default {
        name: 'EventNotificationContainer',
        components: {
            /* eslint-disable vue/no-unused-components */
            EventStateNoDateWarningCard,
            EventStateOnSaleDateFutureWarningCard,
            EventStatePastWarningCard,
            EventStateNonTransactableWarningCard,
            EventStatusCancelledWarningCard,
            EventTicketStatusNotAvailableWarningCard,
            EventStateInactiveWarningCard,
            EventStateNotAvailableWarningCard
            /* eslint-enable vue/no-unused-components */
        },
        props: {
            event: {
                type: Object,
                default: null
            },
            providerPurchaseFlow: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            componentWarning( ) {
                const { ticketsStatus, eventStatus, startDateTimeAt, onSaleStartDateTimeAt, active, transactable } = this.event;

                if( this.providerPurchaseFlow ) {
                    if( !this.event.canBeLinkedOver ) {
                        return  EventStateNotAvailableWarningCard;
                    }
                    return;
                }

                if ( !active ) {
                    return EventStateInactiveWarningCard;
                } else if ( eventStatus === eventConstants.EVENT_STATUS.CANCELLED ) {
                    return EventStatusCancelledWarningCard;
                } else if ( eventUtilsIsPast( this.event ) ) {
                    return EventStatePastWarningCard;
                } else if ( ticketsStatus === eventConstants.TICKETS_STATUS.NOT_AVAILABLE ) {
                    return EventTicketStatusNotAvailableWarningCard;
                } else if ( eventUtilsIsSaleDatesInFuture( this.event ) ) {
                    return EventStateOnSaleDateFutureWarningCard;
                } else if ( !startDateTimeAt || !onSaleStartDateTimeAt ) {
                    return EventStateNoDateWarningCard;
                } else if ( !transactable ) {
                    return EventStateNonTransactableWarningCard;
                } else {
                    return null;
                }
            }
        }
    };
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-flex',{class:{
        'theme-bg-color-quinary': !_vm.loadingTickets && !_vm.tickets.length
    },attrs:{"shrink":""}},[_c('v-flex',{staticClass:"gst-event__venue-img"},[_c('EventTicketVenueImage',{staticClass:"u-mouse-pointer",attrs:{"image-props":{
                src: _vm.venueImageUrl,
                height: 130,
                width: '100%',
                contain: true,
                'aspect-ratio': 1
            },"loading":_vm.loadingTickets}})],1),_c('v-divider')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }

<template>
    <v-flex
        :class="offerClass"
        d-flex
        align-center
        class="gst-ticket-offer-type-icon">
        <div class="d-inline-block gst-ticket-offer-type-icon__icon">
            <BaseIcon :symbol-id="offerIconSymbolId" />
        </div>
        <VClamp
            v-if="lines"
            autoresize
            :max-lines="lines"
            class="d-inline">
            {{ offerLabel }}
        </VClamp>
        <span v-else>
            {{ offerLabel }}
        </span>
    </v-flex>
</template>
<script>
    import VClamp from 'vue-clamp';
    import { isResale as ticketUtilsIsResale } from '@core/utils/ticketUtils';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'TicketTypeIconVariantOne',
        components: {
            VClamp,
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.ticket.ticketOfferTypeIcon'
        },
        props: {
            type: {
                type: String,
                default: 'standard'
            },
            offerName: {
                type: String,
                default: 'standard'
            },
            lines: {
                type: Number,
                default: 2
            }
        },
        computed: {
            offerIconSymbolId( ) {
                return ticketUtilsIsResale( this.type ) ? 'icons--check_circle' : 'icons--ticket';
            },
            offerLabel( ) {
                return this.offerName;
            },
            offerClass( ) {
                return ticketUtilsIsResale( this.type ) ? 'gst-ticket-offer-type-icon--resale' : 'gst-ticket-offer-type-icon--standard';
            }
        }
    };
</script>
<style lang="scss" scoped>
    @import "@scssVariables";

    .gst-ticket-offer-type-icon {
        line-height: 13px;
        font-size: font-size( 'xxxs' );
        font-weight: font-weight( 'bold' );
        text-transform: uppercase;
    }

    .gst-ticket-offer-type-icon.gst-ticket-offer-type-icon--resale {
        svg {
            ::v-deep .gst-svg-icon {
                fill: theme-color( 'success' );
            }
        }

        color: theme-color( 'success' );
    }

    .gst-ticket-offer-type-icon.gst-ticket-offer-type-icon--standard {
        svg {
            ::v-deep .gst-svg-icon {
                fill: theme-color( 'primary' );
            }
        }

        color: theme-color( 'primary' );
    }
</style>

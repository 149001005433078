<template>
    <NotificationWarningCard :title="$t('title')"
        :message="$t('message')"
        class="gst-event-ticket-status-non-transactable-warning-card" />
</template>

<script>
    import NotificationWarningCard from '@core/shared/components/notifications/NotificationWarningCard.vue';

    export default {
        name: 'EventStateNonTransactableWarningCard',
        components: {
            NotificationWarningCard
        },
        i18nOptions: {
            namespaces: '_common',
            keyPrefix: 'messages.eventTicketStatus.nonTransactable'
        }
    };
</script>

<style lang="scss">
$colorCard: #EE2E24;

.gst-event-ticket-status-non-transactable-warning-card.gst-notification-warning-card {
    border-left: 4px solid $colorCard;

    svg .gst-svg-icon {
        fill: $colorCard;
    }
}
</style>

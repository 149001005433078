<template>
    <div class="confirmation-modal d-flex flex-column rounded-lg overflow-x-hidden">
        <div class="confirmation-modal__header d-flex align-center justify-start">
            <slot name="header">
                <h6 v-if="title">
                    {{ title }}
                </h6>
            </slot>
        </div>
        <div class="confirmation-modal__content flex-grow-1">
            <slot name="content">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-if="content" v-html="content"></p>
            </slot>
        </div>
        <div class="confirmation-modal__actions d-flex flex-column justify-center align-stretch flex-md-row justify-md-end">
            <slot name="actions">
                <BaseButton
                    v-if="confirmButton"
                    class="confirmation-modal__actions-confirm-button order-md-last"
                    color="primary"
                    :placeholder="confirmButton"
                    @click="onClickConfirmButtonDo" />
                <BaseButton
                    v-if="cancelButton"
                    class="confirmation-modal__actions-cancel-button gst-btn--link"
                    color="primary"
                    text
                    :placeholder="cancelButton"
                    @click="onClickCancelButtonDo" />
            </slot>
        </div>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';

    export default  ( {
        name: 'ConfirmationModal',
        components: {
            BaseButton
        },
        mixins: [
            CloseModalOnRouteChangeMixin
        ],
        props: {
            title: {
                type: String,
                default: null
            },
            content: {
                type: String,
                default: null
            },
            cancelButton: {
                type: String,
                required: true
            },
            confirmButton: {
                type: String,
                required: true
            },
            onConfirmFn: {
                type: Function,
                required: true
            },
            onCancelFn: {
                type: Function,
                required: true
            },
        },
        methods: {
            onClickCancelButtonDo( ) {
                this.onCancelFn( );
                this.$emit( 'close' );
            },
            onClickConfirmButtonDo( ) {
                this.onConfirmFn( );
                this.$emit( 'close' );
            }
        }
    } );
</script>


<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .confirmation-modal {
        padding: theme-spacing( 0, 4 );
        background-color: theme-color( 'white' );
        min-height: 150px;

        .confirmation-modal__header {
            padding-top: theme-spacing( 6 );
            padding-bottom: theme-spacing( 4 );
            color: theme-color( 'tertiary' );

            h6 {
                font-size: font-size( 'xxs' );
                font-weight: font-weight( medium );
                text-transform: uppercase;
            }
        }

        .confirmation-modal__content {
            p {
                line-height: 1.5;
                margin-bottom: theme-spacing( 0 ) !important;
                font-size: font-size( 's' );
                font-weight: font-weight( 'regular' );
            }
        }

        .confirmation-modal__actions {
            padding: theme-spacing( 6, 0, 4, 0 ) !important;

            button {
                font-size: font-size( 's' );
                font-weight: font-weight( 'medium' );
                border-radius: border-radius( 'm' ) !important;
            }
        }
    }

    @include desktop-only {
        .confirmation-modal {
            .confirmation-modal__header {
                padding: theme-spacing( 6, 5, 4, 5 );
                margin: theme-spacing( 0, n5, 5, n5 );
                background-color: theme-color( 'primary' );
                color: theme-color( 'white' );
                overflow-wrap: break-word;
    
                h6 {
                    font-size: font-size( 'xxl' );
                    font-weight: font-weight( 'medium' );
                    text-transform: unset;
                }
            }
    
            .confirmation-modal__content {
                padding: theme-spacing( 2, 0 );
            }
    
            .confirmation-modal__actions {
                padding: theme-spacing( 6, 0 ) !important;
            }
        }
    }

        
</style>
<template>
    <div class="gst-ticket-offer-card-variant-one" :title="offer.name">
        <div class="d-flex flex-column align-center">
            <TicketTypeIconVariantThree
                :type="ticket.selection"
                :offer-name="offer.name"
                :lines="$vuetify.breakpoint.mdAndUp ? 2 : 0"
                class="gst-ticket-offer-card-variant-one__ticket-type" />
        </div>
        <PriceIcon
            :price="price"
            :points="offerPoints"
            :discounts="offer.discounts"
            :offer="offer"
            :currency="currency"
            :loyalty-currency="loyaltyCurrency"
            :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney"
            :outlined="false"
            class="gst-ticket-offer-card-variant-one__price-icon" />
    </div>
</template>
<script>
    import TicketTypeIconVariantThree from '@core/shared/components/ticket/TicketTypeIconVariantThree.vue';
    import PriceIcon from '@core/shared/components/misc/PriceIcon.vue';
    import { getViewedPriceOfferForEvent as ticketOfferUtilsGetViewedPriceOfferForEvent } from '@core/utils/ticketOfferUtils';

    export default {
        name: 'TicketOfferCardVariantOne',
        components: {
            TicketTypeIconVariantThree,
            PriceIcon
        },
        i18nOptions: {
            keyPrefix: 'components.ticket.ticketOfferCardSmall',
            namespaces: 'shared'
        },
        props: {
            ticket: {
                type: Object,
                required: true
            },
            offer: {
                type: Object,
                required: true
            },
            withPriceIcon: {
                type: Boolean,
                default: () => true
            },
            isLoyaltyCurrencyTypeMoney: {
                type: Boolean,
                required: true
            },
            outlined: {
                type: Boolean,
                default: true
            },
            event: {
                type: Object,
                required: true
            }
        },
        computed: {
            offerPoints( ) {
                return this.offer.bonusPoints;
            },
            currency( ) {
                const { offer } = this;
                return offer.currency;
            },
            loyaltyCurrency( ) {
                const { offer } = this;
                return offer.loyaltyCurrency;
            },
            price( ) {
                const { event, offer } = this;
                return ticketOfferUtilsGetViewedPriceOfferForEvent( event, offer, { variant: 3 } );
            }
        }
    };
</script>
<style lang="scss" scoped>
@import "@scssVariables";

.gst-ticket-offer-card-variant-one {
    position: relative;
    padding-top: theme-spacing( 3 );
    border: 1px solid theme-color( 'quinary' );
    background-color: transparent;
    border-radius: border-radius( 'm' ) !important;

    .gst-ticket-offer-card-variant-one__title {
        color: theme-color( 'tertiary' );
        font-size: font-size( 'xxxs' );
        font-weight: font-weight( 'bold' );
        text-transform: uppercase;
    }

    .gst-ticket-offer-card-variant-one__price-icon {
        width: 100%;
        line-height: 14px;
        font-size: font-size( 'xxs' );
        font-weight: font-weight( 'regular' );
    }
}
</style>

<style lang="scss">
    .gst-ticket-offer-card-variant-one {
        .gst-ticket-offer-card-variant-one__price-icon {
            width: 100% !important;
        }
    }
</style>

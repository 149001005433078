<template>
    <div class="py-4 gst-delivery-instruction">
        <div v-if="listingNotes.deliveryDate" class="gst-text gst-text__title--light pb-3"> 
            {{ listingNotes.deliveryDate }}
        </div>
        <div v-if="listingNotes.deliveryMethod" class="gst-text gst-text__title">
            {{ listingNotes.deliveryMethod }}
        </div>
        <div v-if="listingNotes.deliveryDescription" class="gst-text gst-text__description pb-3">
            {{ listingNotes.deliveryDescription }}
        </div>
        <div v-if="listingNotes.sellerNotes">
            <div class="gst-text gst-text__title">
                {{ $t("ticketDeliveryInformation.notes") }}
            </div>
            <div class="gst-text gst-text__description">
                {{ listingNotes.sellerNotes }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TicketDeliveryInstruction',
        props: {
            listingNotes: {
                type: Object,
                default: () => ( {} ),
            },
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.ticket'
        },
    };
</script>
<style lang="scss" scoped>
@import "@scssVariables";

.gst-delivery-instruction {
    .gst-text {
        line-height: 17px;
        color: theme-color( 'quaternary' );
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.2142857164144516px;
    }

    .gst-text__title {
        font-weight: 600;
    }

    .gst-text__description {
        font-size: 13px;
    }

    .gst-text__title--light {
        line-height: 16px;
        color: theme-color( 'tertiary' );
    }
}

</style>

<template>
    <v-flex d-flex flex-column align center class="gst-event-ticket-detail-item-offer">
        <v-flex xs12 d-flex flex-row align-center>
            <TicketOfferCardVariantOne
                :ticket="ticket"
                :offer="item.offer"
                :event="event"
                :style="{ width: '180px' }"
                :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney"
                class="u-background-transparent gst-event-ticket-detail-content__offer-card u-mouse-pointer" />
            <v-spacer />
            <v-flex
                v-if="isMultipleOffers"
                d-flex
                align-center
                shrink
                justify-content-end>
                <NumericStep
                    v-model="item.quantity"
                    :disable="disableItem"
                    :min="0"
                    :max="maxQuantity"
                    :values="sellableQuantities"
                    :increment-to-max="incrementToMax"
                    :decrement-to-min="decrementToMin" />
            </v-flex>
        </v-flex>
        <v-flex xs-12 d-flex flex-row align-center class="mt-2">
            <v-flex
                v-if="$vuetify.breakpoint.lgAndUp && isMultipleOffers"
                d-flex
                align-center
                shrink
                justify-center
                class="gst-event-ticket-detail-item-offer__select-section" />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span class="gst-event-ticket-detail-item-offer__price" v-html="price"></span>
        </v-flex>
    </v-flex>
</template>

<script>
    import TicketOfferCardVariantOne from '@core/shared/components/ticket/TicketOfferCardVariantOne';
    import NumericStep from '@core/shared/components/input/NumericStep';
    import ticketConstants from '@core/utils/constants/ticket';
    import { getViewedPriceOfferForEvent as ticketOfferUtilsGetViewedPriceOfferForEvent } from '@core/utils/ticketOfferUtils';

    export default {
        name: 'EventTicketDetailOfferItem',
        components: {
            TicketOfferCardVariantOne,
            NumericStep
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            ticket: {
                type: Object,
                required: true
            },
            event: {
                type: Object,
                required: true
            },
            maxQuantity: {
                type: Number,
                required: true
            },
            isMultipleOffers: {
                type: Boolean,
                default: false
            },
            isLoyaltyCurrencyTypeMoney: {
                type: Boolean,
                default: true
            },
            totalQuantitySelected: {
                type: Number,
                default: 0
            },
        },
        computed: {
            price( ) {
                const { item, event } = this;
                const offer = item.offer;

                return ticketOfferUtilsGetViewedPriceOfferForEvent( event, offer, { variant: 2 } );
            },
            sellableQuantities( ) {
                const { item } = this;
                return item.offer && item.offer.ticketType && item.offer.ticketType.sellableQuantities ? item.offer.ticketType.sellableQuantities : [ ];
            },
            incrementToMax( ) {
                const { item, totalQuantitySelected, maxQuantity } = this;
                return ( totalQuantitySelected === 0 || totalQuantitySelected >= 1 ) && item.quantity < maxQuantity;
            },
            decrementToMin( ) {
                const { item, ticket, totalQuantitySelected } = this;
                return ticket.selection === ticketConstants.TYPES.RESALE && item.quantity > 0 && totalQuantitySelected >= 1;
            },
            disableItem( ) {
                const { item, totalQuantitySelected, ticket } = this;
                return ticket.selection === ticketConstants.TYPES.RESALE && item.quantity === 0 && totalQuantitySelected >= 1;
            }
        },
        watch: {
            'item.selected': function ( newValue ) {
                if ( !newValue ) {
                    this.item.quantity = 0;
                }
            },
            'item.quantity': function ( newValue ) {
                this.item.selected = !!newValue;
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-event-ticket-detail-item-offer {
        .gst-event-ticket-detail-item-offer__price {
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxs' );
            font-weight: font-weight( 'regular' );
            letter-spacing: -0.21px;

            b {
                text-transform: capitalize;
            }
        }
    }
</style>

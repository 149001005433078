<template>
    <div class="d-flex flex-column gst-event-ticket-modal rounded-t-lg u-width-100">
        <HeaderVariant2 class="mb-3" @close="$emit('close')">
            <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-center mr-5">
                <TenantLogo class="mr-2" />
            </div>
        </HeaderVariant2>
        <div class="gst-modal-content-scrollable flex-grow-0 px-4">
            <EventTicketVenueImage
                :preview="true"
                class="mb-4"
                :image-props="{
                    src: ticket.venueSeatMapUrl,
                    height: '20vh',
                    width: '100%',
                    contain: true
                }" />
            <EventTicketBanner
                :offers="offers"
                :points="totalBonusPointsEarn"
                :currency="loyaltyCurrency || currency"
                :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney"
                class="gst-event-ticket-modal__bonus-points-banner mb-4" />
            <TicketCardVariantThree
                :ticket="ticket"
                :quantity="quantity"
                :event="event"
                :hide-seats="shouldHideSeatsForResale"
                class="pa-0" />
            <div class="py-4">
                <TicketDateIcon class="mb-4 gst-event-ticket-detail-content__ticket-date-icon" :start-date-time="event.startDateTime" :end-date-time="event.endDateTime" :has-start-time="event.hasStartTime" />
                <TicketLocationIcon
                    :venue="venueInfoLocation"
                    class="gst-event-ticket-detail-content__ticket-location-icon" />
            </div>
            <hr class="gst-hr" />
            <div
                class="pt-4 pb-2 mx-0">
                <EventTicketDetailOfferItem
                    v-for="item in offersModel"
                    :key="item.offer.id"
                    :item="item"
                    :ticket="ticket"
                    :event="event"
                    :max-quantity="getMaxQuantityForOfferModelItem( item )"
                    :is-multiple-offers="offers.length > 1"
                    :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney" />
            </div>
            <hr class="gst-hr" />
            <EventTicketDetailSubtotal
                class="gst-event-ticket-modal__subtotal mt-2"
                :price="totalPrice"
                :market-price="marketTotalPrice"
                :currency="currency"
                :selected-items="sumQuantityOffersModel" />
        </div>
        <div class="px-4 py-2">
            <BaseButton
                class="u-width-100 gst-event-ticket-modal__cta-button"
                :placeholder="$t('_common:buttons.reserveTheseSeats')"
                :width="'100%'"
                :disabled="sumQuantityOffersModel !== quantity"
                @click="checkout" />
        </div>
        <TicketDeliveryInstruction v-if="isSourceTmr && listingNotes" :listing-notes="listingNotes" class="px-2" />
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import TicketCardVariantThree from '@core/shared/components/ticket/TicketCardVariantThree';
    import TicketDateIcon from '@core/shared/components/ticket/TicketDateIcon';
    import TicketLocationIcon from '@core/shared/components/ticket/TicketLocationIcon';
    import HeaderVariant2 from '@core/shared/components/modals/layout/HeaderVariant2';
    import CloseModalOnBreakpointChangeMixin from '@core/mixins/modals/CloseModalOnBreakpointChange';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import TicketDeliveryInstruction from '@core/shared/components/ticket/TicketDeliveryInstruction';
    import EventTicketDetailOfferItem from './components/EventTicketDetailOfferItem.vue';
    import EventTicketDetailMixin from './EventTicketDetailMixin';
    import EventTicketBanner from './EventTicketBanner';
    import EventTicketVenueImage from './components/EventTicketVenueImage';
    import EventTicketDetailSubtotal from './components/subtotals/EventTicketDetailSubtotal.vue';

    export default {
        name: 'EventTicketDetailModal',
        components: {
            BaseButton,
            TicketCardVariantThree,
            EventTicketDetailOfferItem,
            EventTicketVenueImage,
            TicketDateIcon,
            TicketLocationIcon,
            TicketDeliveryInstruction,
            HeaderVariant2,
            EventTicketBanner,
            EventTicketDetailSubtotal
        },
        mixins: [ CloseModalOnRouteChangeMixin, CloseModalOnBreakpointChangeMixin, EventTicketDetailMixin ],
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-event-ticket-modal {
        background-color: theme-color( 'white' );

        .gst-event-ticket-modal__ticket-date-icon,
        .gst-event-ticket-modal__ticket-location-icon {
            color: theme-color( 'quaternary' );
        }

        .gst-event-ticket-modal__offers-list-title {
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxxs' );
            font-weight: font-weight( 'regular' );
            font-weight: font-weight( 'bold' );
            text-transform: uppercase;
        }

        .gst-event-ticket-modal__offer-card {
            margin-top: 1px !important;
            border-width: 1px !important;
        }

        .gst-event-ticket-modal__offer-card--selected {
            margin-top: theme-spacing( 0 ) !important;
            border-color: theme-color( 'success' ) !important;
            border-width: 2px !important;
        }

        .gst-event-ticket-modal__subtotal {
            color: theme-color( 'quaternary' );
            font-weight: font-weight( 'regular' );
            font-weight: font-weight( 'bold' );
        }
    }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gst-event-ticket-image u-height-100",class:{
        'theme-bg-color-quinary': !_vm.loadingTickets && !_vm.tickets.length
    }},[_c('EventTicketVenueImage',{staticClass:"u-height-100",attrs:{"venue-seat-map-src":_vm.venueSeatMapUrl,"venue-map-src":_vm.venueMapUrl,"image-props":{
            src: _vm.venueSeatMapUrl,
            'height': '100%',
            'width': '100%',
            contain: true,
            'aspect-ratio': 1
        },"loading":_vm.loadingTickets}}),_c('div',{staticClass:"gst-event-ticket-image__ticket-limited-availability-warning-chip"},[_c('v-fade-transition',[(_vm.showTicketsFewAvailableWarning)?_c('EventTicketStatusFewAvailableWarningCard',{on:{"close":function($event){return _vm.$emit('close-tickets-few-available-warning')}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div flat class="d-flex flex-column gst-event-ticket-detail-content gst-event-ticket-detail-content--sidebar u-height-100 px-4 u-overflow-auto-y">
        <div
            class="gst-event-ticket-detail-content__toolbar">
            <v-btn
                text
                small
                class="text-capitalize gst-btn--cancel px-0"
                color="primary"
                @click="$emit('cancel')">
                <BaseIcon symbol-id="icons--dropdown_arrow" class="u-rotate-90 gst-event-ticket-detail-content__back-icon" />
                {{ $t('_common:buttons.back') }}
            </v-btn>
        </div>
        <hr class="gst-hr" />
        <EventTicketBanner
            :offers="offers"
            :points="totalBonusPointsEarn"
            :currency="loyaltyCurrency || currency"
            :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney"
            class="gst-event-ticket-detail-content__bonus-points-banner mt-4 flex-shrink-0" />
        <div class="flex-grow-0 px-0 mt-4 pb-0">
            <TicketCardVariantThree
                :ticket="ticket"
                :quantity="quantity"
                :event="event"
                :hide-seats="shouldHideSeatsForResale"
                class="pa-0" />
            <div class="d-flex flex-column py-4" xs="12">
                <TicketDateIcon class="mb-4 gst-event-ticket-detail-content__ticket-date-icon" :start-date-time="event.startDateTime" :end-date-time="event.endDateTime" :has-start-time="event.hasStartTime" />
                <TicketLocationIcon
                    :venue="venueInfoLocation"
                    class="gst-event-ticket-detail-content__ticket-location-icon" />
            </div>
            <hr class="gst-hr" />
            <div
                class="pt-0 pb-2 mx-0">
                <EventTicketDetailOfferItem
                    v-for="item in offersModel"
                    :key="item.offer.id"
                    :item="item"
                    :ticket="ticket"
                    :event="event"
                    :max-quantity="getMaxQuantityForOfferModelItem( item )"
                    :is-multiple-offers="offers.length > 1"
                    :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney"
                    :total-quantity-selected="sumQuantityOffersModel"
                    class="mt-4" />
            </div>
            <hr class="gst-hr" />
            <EventTicketDetailSubtotal
                class="gst-event-ticket-detail-content__subtotal mt-2"
                :price="totalPrice"
                :market-price="marketTotalPrice"
                :currency="currency"
                :selected-items="sumQuantityOffersModel" />
        </div>
        <div class="pa-0 mt-2">
            <BaseButton
                class="u-width-100"
                :placeholder="$t('_common:buttons.reserveTheseSeats')"
                :width="'100%'"
                :disabled="sumQuantityOffersModel !== quantity"
                @click="checkout" />
            <TicketDeliveryInstruction v-if="isSourceTmr && listingNotes" :listing-notes="listingNotes" />
        </div>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import TicketCardVariantThree from '@core/shared/components/ticket/TicketCardVariantThree';
    import TicketDateIcon from '@core/shared/components/ticket/TicketDateIcon';
    import TicketLocationIcon from '@core/shared/components/ticket/TicketLocationIcon';
    import TicketDeliveryInstruction from '@core/shared/components/ticket/TicketDeliveryInstruction';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    import EventTicketDetailMixin from './EventTicketDetailMixin';
    import EventTicketBanner from './EventTicketBanner';
    import EventTicketDetailOfferItem from './components/EventTicketDetailOfferItem.vue';
    import EventTicketDetailSubtotal from './components/subtotals/EventTicketDetailSubtotal.vue';

    export default {
        name: 'EventTicketDetailSidebar',
        components: {
            BaseButton,
            BaseIcon,
            TicketCardVariantThree,
            TicketDateIcon,
            TicketLocationIcon,
            TicketDeliveryInstruction,
            EventTicketDetailOfferItem,
            EventTicketBanner,
            EventTicketDetailSubtotal
        },
        mixins: [
            EventTicketDetailMixin
        ]
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-event-ticket-detail-content--sidebar {
        border-right: 1px solid theme-color( 'quinary' ) !important;
    }

    .gst-event-ticket-detail-content {
        background-color: theme-color( 'white' );

        .gst-event-ticket-detail-content__toolbar {
            margin: theme-spacing( 2, 0 );
        }

        .gst-event-ticket-detail-content__back-icon {
            .gst-svg-icon {
                fill: theme-color( 'primary' );
            }
        }
    }
</style>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-event-ticket-detail-content {
        .gst-event-ticket-detail-content__ticket-date-icon,
        .gst-event-ticket-detail-content__ticket-location-icon {
            color: theme-color( 'quaternary' );
        }

        .gst-event-ticket-detail-content__offers-list-title {
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxxs' );
            font-weight: font-weight( 'regular' );
            font-weight: font-weight( 'bold' );
            text-transform: uppercase;
        }

        .gst-event-ticket-detail-content__offer-card {
            margin-top: 1px !important;
            border-width: 1px !important;
        }

        .gst-event-ticket-detail-content__offer-card--selected {
            margin-top: theme-spacing( 0 ) !important;
            border-color: theme-color( 'success' ) !important;
            border-width: 2px !important;
        }
    }
</style>

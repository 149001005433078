import { render, staticRenderFns } from "./EventBanner.vue?vue&type=template&id=07cd572a&scoped=true&CORE"
import script from "./EventBanner.vue?vue&type=script&lang=js&CORE"
export * from "./EventBanner.vue?vue&type=script&lang=js&CORE"
import style0 from "./EventBanner.vue?vue&type=style&index=0&id=07cd572a&lang=scss&scoped=true&CORE"
import style1 from "./EventBanner.vue?vue&type=style&index=1&lang=scss&CORE"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07cd572a",
  null
  
)

export default component.exports
<template>
    <div class="gst-event-ticket-detail-subtotal u-width-100">
        <div class="d-flex flex-wrap justify-space-between">
            <span class="text-capitalize gst-event-ticket-detail-subtotal__price-label">
                {{ $t('_common:terms.subTotal').toLowerCase() }}
            </span>
            <div class="d-flex gst-event-ticket-detail-subtotal__price">
                <span v-if="showMarketPrice" class="gst-event-ticket-detail-subtotal__market-price mr-2 u-text-strike-though">
                    {{ marketPrice | currencyFull( currency ) }}
                </span>
                {{ price | currencyFull( currency ) }}
            </div>
        </div>
        <div class="d-flex flex-wrap justify-space-between mt-1">
            <span class="text-lowercase gst-event-ticket-detail-subtotal__selected-items">
                {{ selectedItemsFormatted }}
            </span>
            <span class="gst-event-ticket-detail-subtotal__taxes-and-fees-label ml-auto">
                {{ $t('_common:terms.includingTaxesAndFees' ) }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EventTicketDetailSubtotal',
        props: {
            price: {
                type: Number,
                required: true
            },
            marketPrice: {
                type: Number,
                default: null
            },
            currency: {
                type: String,
                required: true
            },
            selectedItems: {
                type: Number,
                default: 0
            }
        },
        computed: {
            selectedItemsFormatted( ) {
                const { selectedItems } = this;
                if ( !selectedItems || selectedItems > 1 ) {
                    return `${selectedItems} ${this.$t( '_common:terms.ticket_plural' )}`;
                }

                return `${selectedItems} ${this.$t( '_common:terms.ticket' )}`;
            },
            showMarketPrice( ) {
                return this.marketPrice && ( this.marketPrice > this.price );
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-event-ticket-detail-subtotal {
        line-height: normal;
        padding: theme-spacing( 4, 2 );
        background: theme-color( 'quinary' );
        border-radius: border-radius( 'xs' );

        > div {
            font-weight: font-weight( 'regular' );
        }

        > div:first-child {
            color: theme-color( 'quaternary' );
            font-weight: font-weight( 'medium' );
        }

        .gst-event-ticket-detail-subtotal__price-label {
            font-size: font-size( 'l' );
        }

        .gst-event-ticket-detail-subtotal__price {
            font-size: font-size( 'xxxl' );
        }

        .gst-event-ticket-detail-subtotal__selected-items,
        .gst-event-ticket-detail-subtotal__taxes-and-fees-label {
            color: theme-color( 'tertiary' );
            font-size: font-size( 's' );
        }

        .gst-event-ticket-detail-subtotal__market-price {
            margin-top: 6px;
            color: theme-color( 'secondary' ) !important;
            font-size: font-size( 'xs' ) !important;

            @include mobile-only {
                padding-left: theme-spacing( 1 ) !important;
                margin-top: theme-spacing( 1 ) !important;
                font-size: font-size( 's' ) !important;
            }
        }
    }
</style>
